import { Col, Row, Switch, Table, message, Form, Input, Image, Button } from "antd";
import { Content } from 'antd/es/layout/layout';
import { LayoutNav, LayoutTabPlatform } from 'components/layout.component';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from "react";
import { $get, $post } from 'services';
import { useForm } from "antd/lib/form/Form";
import Default from 'assets/image/default-customer-service-system.png';
import Line from 'assets/image/line.png';
import { RESPONSE_CODE_CUSTOMER_SERVICES_SOFTWARE_UPDATE } from "constants/response";
import { ENABLE } from "../../../enum/state";

// 站台設置 > 客服軟體設定
const PageMain: React.FC = () => {
  const [form] = useForm();
  const [isEditDone, setIsEditDone] = useState(false);
  const [list, setList] = useState<any>([]);
  const { permissions: $p } = useAccount();
  const { data: customerServiceSoftwareData, isValidating, mutate } = $get({ url: 'api/customerservicesoftware/settings' });

  const transferData: any =
    customerServiceSoftwareData?.Data?.map((item: any) => {
      let icon = '';
      switch (item.Code) {
        case "Default":
          icon = Default;
          break;
        case "Line":
          icon = Line;
          break;
        default:
          icon = '';
      }
      return {
        ...item,
        key: item.Id,
        Icon: icon
      };
    });

  useEffect(() => {
    if (customerServiceSoftwareData?.Data) setList(customerServiceSoftwareData.Data);
  }, [customerServiceSoftwareData]);
  useEffect(() => {
    init();
  }, [list]);

  const init = (validate = false) => {
    if (customerServiceSoftwareData?.Data) {
      const field: any = {};
      customerServiceSoftwareData.Data.map((item: any, i: number) => {
        field[`Code-${i}`] = item.Code;
        field[`Url-${i}`] = JSON.parse(item?.Data || '{}')?.Url;
        field[`IsEnabled-${i}`] = item.IsEnabled;
      })

      form.setFieldsValue(field);
    }
  };

  const save = (formData: any) => {
    const Settings: any = [];
    list.map((item: any, i: number) => {
      const record: any = {};
      record[`Code`] = item.Code;
      record[`Type`] = "Url";
      record[`Data`] = { Url: formData[`Url-${i}`]}
      record[`IsEnabled`] = formData[`IsEnabled-${i}`] ? 1 : 0 ;
      Settings.push(record);
    })
    $post({
      url: 'api/customerservicesoftware/settings/update',
      send: {
        Settings
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        setIsEditDone(false);
        mutate();
      },
      resCode: RESPONSE_CODE_CUSTOMER_SERVICES_SOFTWARE_UPDATE
    })
  };

  const onCancel = () => {
    init()
    setIsEditDone(false)
  }

// 狀態啟用時則該列連結為必填相關
    const [checkedState, setCheckedState] = useState<any>({});

    const onChange = (checked: boolean, index: number) => {
      setCheckedState((prev: any) => ({
        ...prev,
        [index]: checked
      }));
    };

    useEffect(() => {
      if (customerServiceSoftwareData?.Data) {
        const initialCheckedState: any = {};
        customerServiceSoftwareData.Data.forEach((item: any, i: number) => {
          initialCheckedState[i] = item.IsEnabled === ENABLE.enabled;
        });
        setCheckedState(initialCheckedState);
      }
    }, [customerServiceSoftwareData?.Data]);


  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <LayoutTabPlatform activeKey="8" />
        <Form form={form} onFinish={save}>
          <Row align="middle" gutter={[10, 16]}>
            <Col span={24} style={{ display: "flex", justifyContent: "end" }}>
              {
                isEditDone && <>
                  <Button className="mr-1"  onClick={() => onCancel()}>{i18n.t('cancel')}</Button>
                  <Button type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button>
                </>
              }
              {
                !isEditDone && $p('01602') &&
                <Button type="primary" onClick={() => setIsEditDone(!isEditDone)}>{i18n.t('edit')}</Button>
              }
            </Col>
            <Col span={24}>
              <Table
                loading={isValidating}
                size="middle"
                dataSource={customerServiceSoftwareData && transferData}
                columns={customerServiceSoftwareData && [
                  {
                    dataIndex: 'Code',
                    title: i18n.t('software'),
                    width: 250,
                    align: 'left',
                    render: (val, record, i) => {
                      let displayText;
                      switch (val) {
                        case "Default":
                          displayText = i18n.t('builtInCustomerServiceSystem');
                          break;
                        case "Line":
                          displayText = "Line";
                          break;
                        default:
                          displayText = val;
                      }
                      return (
                        <>
                          <Form.Item name={`Code-${i}`}>
                            <Image src={record?.Icon} width={50} height={50} preview={false} />
                            <span className="ml-1">{displayText}</span>
                          </Form.Item>
                        </>
                      );
                    }
                  },
                  {
                    dataIndex: 'Url',
                    title: <><span className="require">*</span>{i18n.t('link')}</>,
                    width: 600,
                    render: (_, record: any, i) => {
                      return (i === 0 ? (
                        <span>-</span>
                      ) : (
                        <Form.Item name={`Url-${i}`}
                                   rules={[
                                     ...(checkedState[i] ? [{ required: true, message: `${i18n.t('required')}` }] : [{required: false}])
                                   ]}
                        >
                          <Input
                            placeholder={`${i18n.t('inputData')}`}
                            disabled={!isEditDone}
                          />
                        </Form.Item>
                      )
                    )}
                  },
                  {
                    dataIndex: 'IsEnabled',
                    title: i18n.t('status'),
                    width: 350,
                    align: 'center',
                    render: (val, record, i) =>{
                      return <Form.Item name={`IsEnabled-${i}`}>
                        <Switch
                          disabled={!isEditDone}
                          onChange={(checked) => onChange(checked, i)}/>
                      </Form.Item>}
                  },
                ]}
                pagination={false}
              />
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;